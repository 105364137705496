
<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card style="border-radius: 10px" flat class="pb-3">
        <v-card-title primary-title class='primary white--text py-3 px-0'>
          <div class="pl-5">Criar certificado</div>
        </v-card-title>
        <card-loading-transparent v-if="loading" class="pt-3"></card-loading-transparent>
        <v-card-text v-if="!loading" class="pb-0">
          <card-loading-transparent loadingText="Salvando" class="mt-3" v-if="saving"></card-loading-transparent>
          <v-form ref="form" v-if="!saving">
            <div v-for="(teacher, idx) in teachers" :key="idx">
              <v-row class="mt-5">
                <v-text-field label="Professor" class="mt-3 mr-2" prepend-icon="mdi-account" v-model="teachers[idx]" :rules="required"></v-text-field>
                <div class="pt-7">
                  <v-btn icon class="error" dark small @click="removeTeacher( idx )"><v-icon size="18">mdi-delete</v-icon></v-btn>
                </div>
              </v-row>
            </div>
          </v-form>
          <v-btn color="primary" small @click="addNew" class="mt-10" :disabled="saving || teachers.length >= 7"><v-icon left>mdi-plus</v-icon> Adicionar </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="createCertificate" :disabled="teachers.length == 0 || saving"><v-icon left>mdi-certificate-outline</v-icon> Gerar </v-btn>
          <v-btn color="error" @click="$router.push({name: 'TeacherCertificateList'})" :disabled="saving"><v-icon  left>mdi-close</v-icon> Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import validations from '@/mixins/validations'
import Api from '@/api/index';

export default {
  name: 'CreateTeacherCertificateDialog',
  mixins: [ validations ],
  data() {
    return {
      dialog: true,
      loading: false,
      teachers: [''],
      saving: false
    }
  },
  methods: {
    createCertificate() {
      if(this.$refs.form.validate()){
        this.saving = true
        let success = true
        
        Api.TeacherCertificates.create(this.teachers, this.$route.params.id).then(r=>{
          let res = {
            type: 'success',
            msg: 'Certificados gerados com sucesso.',
            count: 0
          };
          console.log(r)
          r.data.forEach(el=>{ 
            if(el.error){
              res.count++
              res.type = 'error'
            } 
          });
          if(res.count > 0){ 
            res.msg = `Ocorreu um erro, ${res.count} certificados não foram gerados.`
            success = false
          };
          this.addNotification({type: res.type , msg: res.msg})
        }).catch((err)=>{
          console.log(err)
          success = false
          this.addNotification({type: 'error', msg: `Erro: ${err}`})
        }).finally(() => {
          this.saving = false
          console.log('finally')
          if(success)this.$router.push({name: 'TeacherCertificateList', params: {success: success}})
        })
      }
    },
    addNew(){
      this.teachers.push('')
    },
    removeTeacher(idx){
      this.teachers.splice(idx, 1)
    },
    ...mapActions({
      addNotification: 'Notification/add'
    })
  },
}
</script>

<style scoped>

</style>